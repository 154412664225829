.App {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

button {
  margin: 10px;
  padding: 10px;
  border: none;
  background-color: white;
  color: black;
  font-size: 20px;
  font-weight: bold;
}
